import React, { useEffect, useState } from 'react';
import logo from './images/logo.png';
import { Carousel } from 'react-bootstrap';
import FlipDown from './flipdown';
import { Formik, Field, ErrorMessage } from 'formik';
import { Link } from "react-scroll";

function App() {
  useEffect(() => {
    var twoDaysFromNow = (new Date(2021, 1, 6, 9).getTime() / 1000);
    new FlipDown(twoDaysFromNow, 'countdown').start()
  }, []);

  const [submitting, setSubmitting] = useState(false);

  return (
    <div className="position-relative">
      <header className="header">
        <div className="d-flex justify-content-between px-4 align-items-center flex-row">
          <ul className="nav desktop-nav d-none d-md-flex flex-column flex-lg-row flex-lg-nowrap">
            <li className="nav-item text-left text-lg-center">
              <Link className="nav-link text-white" to="about" smooth={true} offset={-70} duration={1000}>A propos du MPS</Link>
            </li>
            <li className="nav-item text-left text-lg-center">
              <Link className="nav-link text-white" to="contact" smooth={true} offset={-70} duration={1000}>Nous contacter</Link>
            </li>
          </ul>
          <a href="/" title="Accueil" className="text-center">
            <img src={logo} alt="Logo" className="logo" />
          </a>
          <div className="social d-none d-md-flex align-items-center justify-content-center justify-content-md-end mt-3 mt-md-0">
            <a target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/CongresMPS/" className="px-3 text-white text-decoration-none">
              <i className="icon-facebook"></i>
            </a>
            <a target="_blank" rel="noreferrer noopener" href="https://twitter.com/CongresMps" className="px-3 text-white text-decoration-none">
              <i className="icon-twitter"></i>
            </a>
            <a href="https://youtube.com" className="text-white pl-3 text-decoration-none">
              <i className="icon-youtube"></i>
            </a>
          </div>
        </div>
      </header>
      <section className="carousel-container">
        <ul className="d-md-none nav mobile-nav w-100 flex-row justify-content-between position-absolute bottom-0">
          <li className="nav-item text-center">
            <Link className="nav-link text-white center" to="about" smooth={true} offset={-70} duration={1000}>A propos du MPS</Link>
          </li>
          <li className="nav-item text-center">
            <Link className="nav-link text-white text-center" to="contact" smooth={true} offset={-70} duration={1000}>Nous contacter</Link>
          </li>
        </ul>
        <div className="carousel-overlay position-absolute"></div>
        <Carousel indicators={false} controls={false}>
          <Carousel.Item>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="bg-white py-4">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <div class="alert alert-secondary mb-4" role="alert">
                <h4 className="text-center">COVID-19</h4>
                <p className="text-dark text-center">
                  En raison de la pandémie de COVID-19, le Congrès Extraordianaire d'Investiture du Candidat du MPS est précédé des pré-congrès provinciaux qui se tiennent <b>en un(1) seul jour</b>, à compter du 28 au  30 janvier 2021.
                </p>
              </div>
              <p>9ème Congrès Extraordinaire</p>
              <p><strong>Premières élections législatives et locales de la 4eme République</strong></p>
              <h3>En mouvement vers la victoire;</h3>
              <h3>La victoire pour le progrès du Tchad</h3>
              <h5 className="my-5">
                <span>6 février 2021</span>
                <br />
                <span>Palais du 15 janvier - N’Djamena</span>
              </h5>
              <h4 className="text-center">A propos du MPS</h4>
              <p className="text-dark text-center mb-4" id="about">
                Notre engagement est de construire le Tchad de Demain.<br /><br />
                Le Mouvement Patriotique pour le Salut est le principal parti de la Majorité présidentielle au Tchad. Il s’inscrit dans le courant social-démocrate et se caractérise par son ancrage local et la vitalité de son organisation. <br /><br />
                À l’occasion de la première élection présidentielle de la 4e République, le MPS tiendra son 9e Congrès Extraordinaire. La mission du Congrès du MPS est de désigner et investir le Candidat du MPS à l’élection présidentielle d’Avril 2021.<br /><br />
                Le Congrès offre aux militant.e.s et aux sympathisant.e.s. l'opportunité unique de désigner leur Champion qui durant les 5 années à venir œuvrera pour bâtir le future du Tchad !
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 countdown col-lg-6 pb-5 bg-white d-flex flex-column align-items-center justify-content-center">
              <h3>Il reste</h3>
              <div className="">
                <div id="countdown" className="flipdown"></div>
              </div>
            </div>
            <div className="col-12 col-lg-6 p-5 bg-secondary d-flex flex-column align-items-center justify-content-center">
              <h3>Nous contacter</h3>
              <p className="text-dark text-center mt-3">
                <strong>Restez informés des dernières mises à jour à propos du congrès et des prochains événements du parti.</strong>
              </p>
              <Formik
                initialValues={{ lastN: '', firstN: '', whats: '' }}
                validate={values => {
                  let errors = {};
                  if (!values.lastN) {
                    errors.lastN = 'Obligatoire';
                  }
                  if (!values.firstN) {
                    errors.firstN = 'Obligatoire';
                  }
                  if (!values.whats) {
                    errors.whats = 'Obligatoire';
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  setSubmitting(true);
                  const formData = new FormData();
                  formData.append('firstN', values.firstN);
                  formData.append('lastN', values.lastN);
                  formData.append('whats', values.whats);
                  formData.append('submitted', true);
                  fetch(process.env.REACT_APP_API_URL, {
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: formData
                  })
                    .then(res => res.json())
                    .then(
                      (result) => {
                        setSubmitting(false);
                        alert(result.msg);
                      },
                      (error) => {
                        setSubmitting(false);
                        alert(error);
                      }
                    )
                }}
              >
                {({ handleSubmit }) => (
                  <form className="w-100" onSubmit={handleSubmit}>
                    <div className="form-row mt-3">
                      <div className="col">
                        <Field type="text" name="lastN" className="form-control" placeholder="Votre nom" />
                        <ErrorMessage name="lastN">{msg => <div className="text-danger text-small">{msg}</div>}</ErrorMessage>
                      </div>
                      <div className="col">
                        <Field type="text" name="firstN" className="form-control" placeholder="Vos prénoms" />
                        <ErrorMessage name="firstN">{msg => <div className="text-danger text-small">{msg}</div>}</ErrorMessage>
                      </div>
                    </div>
                    <div className="form-row mt-3">
                      <div className="col">
                        <Field name="whats" type="text" className="form-control" placeholder="Votre numéro WhatsApp" />
                        <ErrorMessage name="whats">{msg => <div className="text-danger text-small">{msg}</div>}</ErrorMessage>
                      </div>
                    </div>
                    <div className="form-row mt-3">
                      <div className="col">
                        <button disabled={submitting} className="btn btn-primary btn-block" type="submit">S’incrire</button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
      <section className="quote-container">
        <div className="carousel-container">
          <div className="carousel-overlay position-absolute"></div>
          <Carousel nextIcon={(<i aria-hidden="true" className="icon-chevron-right" />)} prevIcon={(<i aria-hidden="true" className="icon-chevron-left" />)}>
            <Carousel.Item>
              <Carousel.Caption>
                <h5 className="text-white">Un Tchad Fort</h5>
                <p>Œuvrer pour un Tchad en paix, respecté et impliqué dans son environnement régional et international.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <h5 className="text-white">Un Tchad Solidaire</h5>
                <p>Donner la possibilité à chaque citoyen d’accéder à l’eau et à la santé, au logement, à l’énergie et à la mobilité.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <h5 className="text-white">Un Tchad Prospère</h5>
                <p>Bâtir un Tchad dynamique, fort économiquement, respectueux de l’environnement.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
        {/* <div className="container-fluid">
          <div className="row">
            <div className="d-flex col-lg-6 quote-img">
              <div className="quote-overlay position-absolute"></div>
            </div>
            <div className="col-12 col-lg-6 quote d-flex flex-column align-items-center justify-content-center">
              <blockquote className="position-relative">
                <p className="text-white font-weight-500 font-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed posuere mollis nisi. Pellentesque accumsan ac nibh vel sollicitudin. Interdum et malesuada fames ac ante ipsum primis in faucibus.
              </p>
                <span className="font-weight-bold text-secondary d-block mt-4">Hamid Koko, President de la Jeunesse du MPS</span>
              </blockquote>
            </div>
          </div>
        </div> */}
      </section>
      <section className="location">
        <iframe title="map" className="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.784357489013!2d15.076880914806116!3d12.126901891413933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x111962334b4509a1%3A0x2839e0c653a3202d!2sPalais%20du%2015%20janvier!5e0!3m2!1sen!2sfr!4v1572383770341!5m2!1sen!2sfr" width="800" height="600" frameBorder="0" style={{ border: 0 }} allowFullScreen=""></iframe>
      </section>
      <footer className="footer text-center pt-3 pb-5 bg-white">
        <img src={logo} alt="Logo" className="logo" />
        <h4 className="my-5">Retrouvez-nous sur les réseaux sociaux</h4>
        <div className="social d-flex align-items-center justify-content-center">
          <a href="https://www.facebook.com/CongresMPS/" className="mr-3">
            <i className="icon-facebook"></i>
          </a>
          <a href="https://twitter.com/CongresMps" className="mr-3">
            <i className="icon-twitter"></i>
          </a>
          <a href="https://youtube.com">
            <i className="icon-youtube"></i>
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
